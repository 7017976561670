import { notifications } from '@/app/notifications'
import IssueFormInModal from '~/themes/base/components/shop/IssueFormInModal'

export default {
  extends: IssueFormInModal,
  methods: {
    async submit() {
      const isValid = await this.$validator.validate()
      if (!isValid) return
      this.startLoading()
      const response = await this.$store.dispatch('user/CONTACT_FORM', {
        email: this.input.email,
        name: this.input.name,
        text: `${this.input.question} -- Dotaz byl odeslán z: ${window.location.href}`,
      })
      if (response) {
        this.input.email = ''
        this.input.name = ''
        this.input.question = ''
        this.hide()
        notifications.success(this.$t('IssueFormInModal.notifications.success'))
      }
      this.stopLoading()
    },
  },
}
